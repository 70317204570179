<template>
    <section id="form" ref="form" :class="[
        'form-section',
        isBackgroundPrimary ? 'bg-gradient-primary text-white' : '',
        'pt-1 min-vh-100 rounded'
    ]">
        <div class="container d-flex flex-column justify-content-center h-100 pt-3">
            <h1 :class="[
                'text-center',
                isBackgroundPrimary ? 'text-white' : 'text-primary',
            ]">
                Joindre Aqua<span class="text-info">{{ capitalize(form.specialty) }}</span>
            </h1>

            <b-row>
                <b-col sm="1" md="3"></b-col>

                <b-col sm="10" md="6" class="mt-1 pt-3 pb-2 px-5 bg-primary rounded-lg">
                    <h2 :class="[
                        'text-center',
                        'text-white',
                    ]">
                        Demande un compte
                    </h2>
                    <h5 class="text-center text-muted">
                        Nous vous contacterons
                    </h5>

                    <!-- New Form Section -->
                    <b-form @submit.prevent="handleSubmit" class="mt-2">
                        <b-row>

                            <!-- Specialties -->
                            <b-col sm="1" md="3"></b-col>
                            <b-col sm="10" md="6">
                                <b-form-group label-class='text-white font-weight-bold text-center' label="Spécialité*"
                                    label-for="specialty-dropdown">
                                    <b-form-select :disabled="specialty == 'medico'" id="specialty-dropdown"
                                        v-model="form.specialty" :options="specialties"
                                        class="text-primary text-center font-weight-bold" />
                                </b-form-group>
                            </b-col>

                            <!-- Nom Complet -->
                            <b-col cols="12">
                                <b-form-group label-class='text-white font-weight-bold' label="Nom Complet*"
                                    label-for="vi-full-name">
                                    <b-input-group class="input-group-merge">
                                        <b-input-group-prepend is-text>
                                            <feather-icon class="text-primary" icon="UserIcon" />
                                        </b-input-group-prepend>
                                        <b-form-input id="vi-full-name" v-model="form.fullName"
                                            placeholder="Nom Complet" class="pl-1 text-dark" />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <!-- Mobile -->
                            <b-col cols="12">
                                <b-form-group label-class='text-white font-weight-bold' label="Numéro de téléphone*"
                                    label-for="vi-mobile">
                                    <b-input-group class="input-group-merge">
                                        <b-input-group-prepend>
                                            <b-dropdown class="wrap-dropdown" v-model="form.dialCode" variant="light"
                                                scrollable>
                                                <template #button-content>
                                                    <span v-if="form.dialCode" class="text-primary">{{ form.dialCode
                                                        }}</span>
                                                    <feather-icon v-else class="text-primary" icon="SmartphoneIcon" />
                                                </template>
                                                <b-dropdown-item v-for="code in countryCodes" :key="code.name"
                                                    @click="form.dialCode = code.dial_code">
                                                    {{ code.name }} ({{ code.dial_code }})
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-input-group-prepend>
                                        <b-form-input id="vi-mobile" v-model="form.mobile" type="number"
                                            placeholder="Mobile" class="pl-1 text-dark" />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <!-- Email -->
                            <b-col cols="12">
                                <b-form-group label-class='text-white font-weight-bold' label="Email*"
                                    label-for="vi-email">
                                    <b-input-group class="input-group-merge">
                                        <b-input-group-prepend is-text>
                                            <feather-icon class="text-primary" icon="MailIcon" />
                                        </b-input-group-prepend>
                                        <b-form-input id="vi-email" v-model="form.email" type="email"
                                            placeholder="Email" class="pl-1 text-dark" />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <!-- Ville -->
                            <b-col cols="12">
                                <b-form-group label-class='text-white font-weight-bold' label="Ville"
                                    label-for="vi-city">
                                    <b-input-group class="input-group-merge">
                                        <b-input-group-prepend is-text>
                                            <feather-icon class="text-primary" icon="MapPinIcon" />
                                        </b-input-group-prepend>
                                        <b-form-input id="vi-city" v-model="form.city" placeholder="Ville"
                                            class="pl-1 text-dark" />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" class="text-center text-white">
                                <small>En cliquant sur "Envoyer" j'accepte les
                                    <a href="#" class="text-info">CGU et CGV</a> de aqua-medico.com
                                </small>
                            </b-col>

                            <!-- Reset and Submit Buttons -->
                            <b-col cols="12" class="text-center">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit"
                                    variant="gradient-success" class="mr-1 mt-2 px-5">
                                    Envoyer
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                    <!-- End of Form Section -->
                </b-col>
            </b-row>
        </div>
    </section>
</template>

<script>
import {
    BRow, BCol, BFormGroup, BFormInput, BFormSelect, BForm, BButton, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import countryCodes from '../../countryCodes';

export default {
    name: 'FormSection',
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BInputGroup,
        BInputGroupPrepend,
        BForm,
        BButton,
        BDropdown,
        BDropdownItem,
    },
    props: {
        isBackgroundPrimary: {
            type: Boolean,
            default: false
        },
        specialty: {
            type: String,
            default: null
        }
    },
    directives: {
        Ripple,
    },
    data() {
        const specialties = [
            { text: 'Choisir une spécialité', value: 'medico', disabled: true },
            { text: 'Dentiste', value: 'dentist' },
            { text: 'Cardiologue', value: 'cardio' },
            { text: 'Dermatologue', value: 'dermato' },
        ];

        return {
            form: {
                specialty: this.specialty || 'medico',
                fullName: '',
                email: '',
                dialCode: '+212',
                mobile: '',
                city: '',
            },
            countryCodes,
            specialties
        };
    },
    computed: {
        specialtyName() {
            const selectedSpecialty = this.specialties.find(spec => spec.value === this.form.specialty);
            return selectedSpecialty ? selectedSpecialty.text : 'Medico';
        }
    },
    methods: {
        handleSubmit() {
            console.log(this.form);
        },
        capitalize(s) {
            return s.charAt(0).toUpperCase() + s.slice(1);
        }
    }
};
</script>

<style scoped>
.min-vh-100 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Responsive font size */
.form-section h1 {
    font-size: 2.5rem;
    /* Adjust as needed */
}

@media (max-width: 768px) {
    .form-section h1 {
        font-size: 2rem;
    }
}

@media (max-width: 576px) {
    .form-section h1 {
        font-size: 1.5rem;
    }
}

.wrap-dropdown /deep/ .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
}
</style>